import React from "react"
import Img from "gatsby-image"

const ProjectHero = (props) => {
  const { title, subtitle, color, image } = props;
  return (
    <section className={`Hero Hero--project outer-padding-x bg-${color}`}>
      <div className="Hero__inner">
        <div className="Hero--project__content fadein">
          <h1>{title}</h1>
          <div className="max-width">
            <h3>{subtitle}</h3>
          </div>
        </div>
        <figure className="Hero--project__image">
          <Img fluid={image} />
        </figure>
      </div>
    </section>
  )
}

export default ProjectHero