import React from "react"

const ProjectDetails = ({client, website, agency, year, category, role, technologies}) => (
  <div className="fadein">
    <p className="has-small-font-size color-bluegray no-margin">Client</p>
    <p className="has-small-font-size">{client}</p>
    <p className="has-small-font-size color-bluegray no-margin">Visit site</p>
    <p className="has-small-font-size">{website !== '#' ? <a href={website} target="_blank">{website.replace(/^https?:\/\//,'')}</a> : <span>Coming soon</span>}</p>
    {
      agency !== false ? (
        <>
          <p className="has-small-font-size color-bluegray no-margin">Agency</p>
          <p className="has-small-font-size">{agency}</p>
        </>
      ) : null
    }
    <p className="has-small-font-size color-bluegray no-margin">Year</p>
    <p className="has-small-font-size">{year}</p>
    <p className="has-small-font-size color-bluegray no-margin">Category</p>
    <p className="has-small-font-size">{category}</p>
    <p className="has-small-font-size color-bluegray no-margin">Role</p>
    <p className="has-small-font-size">{role}</p>
    <p className="has-small-font-size color-bluegray no-margin">Technologies</p>
    <p className="has-small-font-size">{technologies}</p>
  </div>
)

export default ProjectDetails