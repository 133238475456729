import React from "react"
import classNames from 'classnames';
import PropTypes from "prop-types"
import Img from "gatsby-image"

const ProjectSection = ({ type, images, bgcolor, content, reversed, video, fullwidth, extraClass }) => {
  const sectionClasses = classNames('ProjectSection outer-padding-y', {
    'ProjectSection--columns': type === 'columns',
    'ProjectSection--columns--images': type === 'image-columns',
    'ProjectSection--default': type === 'default',
    'ProjectSection--video': video,
    [`bg-${bgcolor}`]: bgcolor,
    'outer-padding-x': !fullwidth,
    'ProjectSection--reversed': reversed,
    [`${extraClass}`]: extraClass,
  });
  return (
    <section className={sectionClasses}>
      <div className="fadein">
        {type === 'default' ? (
          <>
            {content ? content : null}
            {images ? images.map((image, i) => <Img key={i} fluid={image} /> ) : null}
            {video ? <video className="fadein video" playsInline muted loop><source src={video} type="video/mp4"/></video> : null}
          </>
        ) : null}
        {type === 'columns' ? (
          <div className="grid-x grid-padding-x--big">
            <div className="cell medium-6">{images ? images.map((image, i) => <Img key={i} fluid={image} /> ) : null}</div>
            <div className="cell medium-6">{content ? content : null}</div>
          </div>
        ) : null}
        {type === 'image-columns' ? (
          <div className="grid-x grid-padding-x--big">
            <div className="cell medium-6">{<Img fluid={images[0]} />}</div>
            <div className="cell medium-6">{<Img fluid={images[1]} />}</div>
          </div>
        ) : null}
      </div>
    </section>
  )
}

ProjectSection.propTypes = {
  type: PropTypes.string,
}

ProjectSection.defaultProps = {
  type: 'default'
}

export default ProjectSection