import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "../../components/seo"
import ProjectsData from "../../data/projects"
import InnerLayout from "../../components/InnerLayout"
import InnerWrapper from "../../components/InnerWrapper"
import ProjectHero from "../../components/project/Hero"
import ProjectWrapper from "../../components/project/Wrapper"
import ProjectIntro from "../../components/project/Intro"
import ProjectSection from "../../components/project/Section"
import TadaCompOne from "../../components/project/tada/TadaCompOne"
import ProjectNext from "../../components/project/Next"

import tadaVideo from '../../images/tada/tada-video.mp4'


const Tada = (pageContext) => {
  const projectsData = ProjectsData();
  const projectId = 2;
  const projectData = projectsData.find(data => data.id === projectId);

  const images = useStaticQuery(graphql`
    query {
      tadaMockupOne: file(relativePath: { eq: "tada/tada-home-mockup.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tadaMockupTwo: file(relativePath: { eq: "tada/tada-work-mockup.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tadaMockupThree: file(relativePath: { eq: "tada/tada-case-mockup.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tadaImageOne: file(relativePath: { eq: "tada/tada-about.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const contentSectionOne = (
    <div>
      <h3>Playful and bright agency site</h3>
      <p>It was important to convey the company culture while still focusing on services, approach and previous work showcasing the capabilities of the agency. </p>
      <ul className="list-unstyled"><li>- Dynamic payoff</li><li>- SVG animations</li><li>- Impressive case studies</li><li>- CMS integration allowing easy editing and creation of pages, projects, services and employees</li><li>- Confetti shooter 404 game</li><li>- News ticker module</li><li>- Blog</li><li>- Launch mode</li><li>- Playful forms</li></ul>
    </div>
  );

  return (
    <InnerLayout noTopPadding>
      <SEO title="Tada project" bodyClass="ProjectPage" />
      <ProjectHero 
        title={projectData.title}
        subtitle={projectData.excerpt}
        color={projectData.color}
        image={projectData.image}
      />
      <InnerWrapper>
        <ProjectWrapper>
          <ProjectIntro
            client={projectData.client}
            website={projectData.website}
            agency={projectData.agency}
            year={projectData.year}
            category={projectData.category}
            role={projectData.role}
            technologies={projectData.technologies}
            problem="Norwegian agency formerly known as Iversen Skogen owed its name to a company merge and decided it was time for a new name and identity embracing its culture. The new name was Tada, expressing the joy and excitement reflected in their work and company culture. Creating a new website it obviously needed to reflect this new brand."
            solution="A new vibrant website emerged with bright colors, clever animations, impressive case studies and playful interactions. The new site clearly distinguishes the agency from its competitors and tells how the agency approaches projects with enthusiasm and excitement. Confetti shooting contact forms and cheerful animations make sure to create a lasting impression."
          />
          <ProjectSection
            type="columns"
            bgcolor="lightgray"
            content={contentSectionOne}
            images={[images.tadaMockupOne.childImageSharp.fluid]}
          />
          <TadaCompOne />
          <ProjectSection
            type="image-columns"
            bgcolor="lightgray"
            content={contentSectionOne}
            images={[images.tadaMockupTwo.childImageSharp.fluid, images.tadaMockupThree.childImageSharp.fluid]}
            reversed
          />
          <ProjectSection
            type="default"
            bgcolor="white"
            video={tadaVideo}
          />
          <ProjectSection
            bgcolor="white"
            images={[images.tadaImageOne.childImageSharp.fluid]}
            fullwidth
          />
          <ProjectNext project={2} />
        </ProjectWrapper>
      </InnerWrapper>
    </InnerLayout>
  )
}

export default Tada