import React from "react"

const ProjectIntroText = ({problem, solution}) => (
  <>
    <h2 className="fadein">The challenge</h2>
    <p className="fadein">{problem}</p>
    <h2 className="fadein">The solution</h2>
    <p className="fadein">{solution}</p>
  </>
)

export default ProjectIntroText