import React from "react"

import animatedGifOne from '../../../images/tada/animated-illustration-1.gif'
import animatedGifTwo from '../../../images/tada/animated-illustration-2.gif'
import animatedGifThree from '../../../images/tada/animated-illustration-3.gif'
import animatedGifFour from '../../../images/tada/animated-illustration-4.gif'
import animatedGifFive from '../../../images/tada/animated-illustration-5.gif'
import animatedGifSix from '../../../images/tada/animated-illustration-6.gif'

const TadaCompOne = () => {
  return (
    <section className="ProjectSection ProjectSection--TadaOne outer-padding-y outer-padding-x bg-white">
      <div>
        <figure className="fadein">
          <img src={animatedGifOne} alt="Animated svg illustration"/>
        </figure>
        <figure className="fadein">
          <img src={animatedGifThree} alt="Animated svg illustration"/>
        </figure>
      </div>
      <div>
        <figure className="fadein">
          <img src={animatedGifTwo} alt="Animated svg illustration"/>
        </figure>
        <figure className="fadein">
          <img src={animatedGifFour} alt="Animated svg illustration"/>
        </figure>
      </div>
      <div>
        <figure className="fadein">
          <img src={animatedGifFive} alt="Animated svg illustration"/>
        </figure>
        <figure className="fadein">
          <img src={animatedGifSix} alt="Animated svg illustration"/>
        </figure>
      </div>
    </section>
  )
}

export default TadaCompOne