import React from "react"

import ProjectDetails from "./Details"
import ProjectIntroText from "./IntroText"

const ProjectIntro = ( {client, website, agency, year, category, role, technologies, problem, solution} ) => (
  <section className="ProjectIntro outer-padding-x outer-padding-y">
    <div className="grid-x grid-padding-x--big">
      <div className="cell large-4">
        <ProjectDetails
          client={client}
          agency={agency}
          website={website}
          year={year}
          category={category}
          role={role}
          technologies={technologies}
        />
      </div>
      <div className="cell large-8">
        <ProjectIntroText
          problem={problem}
          solution={solution}
        />
      </div>
    </div>
  </section>
)

export default ProjectIntro