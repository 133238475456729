import React from "react"
import CasePreview from "../../components/projects/CasePreview"
import ProjectsData from '../../data/projects'

const NextProject = ({ project }) => {
  const cases = ProjectsData();
  const nextProject = cases[project];
  return (
    <div className="ProjectNext outer-padding-y outer-padding-x">
      
      <div className="ProjectNext__inner">
      <h4 className="ProjectNext__title">Next project</h4>
        <CasePreview image={nextProject.image} title={nextProject.title} category={nextProject.category} description={nextProject.description} color={nextProject.color} url={nextProject.url} />
      </div>
    </div>
  )
}

export default NextProject